import React from "react";
import { Tooltip } from "react-tooltip";

type StackProps = {
  src: string;
  alt: string;
  tooltipId: string;
  value: string;
  id: number;
};
const StackCard = ({ id, src, alt, tooltipId, value }: StackProps) => {
  return (
    <>
      <div
        data-tooltip-id={tooltipId}
        className="border border-gray-300 rounded-lg animate__animated animate__pulse animate__infinite devLanguageItem"
      >
        <img src={src} alt={alt} className="p-8 w-28 h-28 object-contain" />
      </div>
      <Tooltip
        id={tooltipId}
        place={id < 9 ? "top" : "bottom"}
        content={value}
        variant="light"
      />
    </>
  );
};

export default StackCard;
