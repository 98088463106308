import React from "react";
import { LinkIcon } from "@heroicons/react/solid";
import SheBeLady from "../../assets/shebelady.png";
import SheBeLadyLogo from "../../assets/sbl.png";
import Splitsheet from "../../assets/splitsheet.png";
import SplitsheetLogo from "../../assets/splitsheetlogo.png";
import CodeQuips from "../../assets/codequips.png";
import CodeQuipsLogo from "../../assets/Codequips.svg";
import AcademicPlanningUnit from "../../assets/apu.png";
import TellivestLogo from "../../assets/tellivest.svg";
import Tellivest from "../../assets/Tellivest.png";
import CruLogo from "../../assets/crulogo.jpg";
import HiringDelightLogo from "../../assets/hiring-delight.png";
import HiringDelight from "../../assets/hiring-delight-2.png";
import { projects } from "../../utils/data";
import Bounce from "react-reveal/Bounce";
import Roll from "react-reveal/Roll";

const Projects = () => {
  return (
    <>
      <div className="bg-slate-900">
        <Bounce bottom cascade>
          <div className="bg-slate-900 py-6 lg:py-8" id="projects">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-sm pb-12 pt-24 font-semibold tracking-wide line-after">
                <span className="font-extrabold text-3xl text-indigo-400">
                  Projects
                </span>
              </h2>

              <div className="animate__animated animate__bounceInDown ">
                <div className="mb-6 bg-slate-900 py-6 lg:py:8 pb-8">
                  <div className="relative py-24 px-8  bg-indigo-500  rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                    <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
                      <img
                        src={HiringDelight}
                        alt="Hiring Delight"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="relative lg:col-span-1">
                      <img
                        className="w-24 h-24 object-cover"
                        src={HiringDelightLogo}
                        alt="Tellivest Logo"
                      />
                      <blockquote className="mt-6 text-white">
                        <p className="text-xl font-medium sm:text-2xl">
                          Hiring Delight is a versatile and high-performing
                          product that enables project leaders to manage project
                          structure, capacity, and tasks based on the demands of
                          their organizations.
                        </p>
                        <footer className="mt-6">
                          <a
                            href="https://hiring-delight.vercel.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                          >
                            <LinkIcon
                              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            View
                          </a>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-6 bg-slate-900 py-6 lg:py:8 pb-8">
                <div className="relative py-24 px-8  bg-indigo-500  rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                  <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
                    <img
                      src={Tellivest}
                      alt="Tellivest"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="relative lg:col-span-1">
                    <img
                      className="w-24 h-24 object-cover"
                      src={TellivestLogo}
                      alt="Tellivest Logo"
                    />
                    <blockquote className="mt-6 text-white">
                      <p className="text-xl font-medium sm:text-2xl">
                        Tellivest is a social media platform for investors. We
                        are removing the noise and providing the right
                        information individuals need to invest properly
                      </p>
                      <footer className="mt-6">
                        <a
                          href="https://tellivest.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                        >
                          <LinkIcon
                            className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          View
                        </a>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>

              <div className="relative py-24 px-8  bg-indigo-500  rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
                  <img
                    src={SheBeLady}
                    alt="SheBeLady"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="relative lg:col-span-1">
                  <img
                    className="h-12 w-auto"
                    src={SheBeLadyLogo}
                    alt="SheBeLady"
                  />
                  <blockquote className="mt-6 text-white">
                    <p className="text-xl font-medium sm:text-2xl">
                      A web app for purchasing tickets for an event from
                      Eventbrite and providing an iframe for live streaming the
                      She Be Lady 2021 concert.
                    </p>
                    <footer className="mt-6">
                      <a
                        href="https://she-be-lady.vercel.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                      >
                        <LinkIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        View
                      </a>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-slate-900 py-6 lg:py-8">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative py-24 px-8  bg-indigo-500  rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
                  <img
                    src={Splitsheet}
                    alt="Splitsheet"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="relative lg:col-span-1">
                  <img
                    className="h-12 w-auto"
                    src={SplitsheetLogo}
                    alt="splitsheet"
                  />
                  <blockquote className="mt-6 text-white">
                    <p className="text-xl font-medium sm:text-2xl">
                      Recognizing the importance of a platform for artists to
                      sign deals and create splitsheets, so I developed a web
                      app for that specific purpose at Sparkplug. The main
                      objective of this app is to provide artists with the
                      ability to easily view and sign their splitsheets.
                    </p>
                    <footer className="mt-6">
                      <a
                        href="https://splitsheet.herokuapp.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                      >
                        <LinkIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        View
                      </a>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-slate-900 py-6 lg:py-8">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative py-24 px-8  bg-indigo-500  rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
                  <img
                    src={CodeQuips}
                    alt="Codequips"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="relative lg:col-span-1">
                  <img
                    className="w-48 h-16 object-cover"
                    src={CodeQuipsLogo}
                    alt="codequips"
                  />
                  <blockquote className="mt-6 text-white">
                    <p className="text-xl font-medium sm:text-2xl">
                      A quirky, good feeling web app used for adding coding
                      jokes or quips. This platform gives its users a nice laugh
                      while also adding quips of their own.
                    </p>
                    <footer className="mt-6">
                      <a
                        href="https://codequips.vercel.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                      >
                        <LinkIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        View
                      </a>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-slate-900 py-6 lg:py-8">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative py-24 px-8  bg-indigo-500  rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
                <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
                  <img
                    src={AcademicPlanningUnit}
                    alt="Academic planning unit"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="relative lg:col-span-1">
                  <img
                    className="h-12 w-auto"
                    src={CruLogo}
                    alt="crawford university"
                  />
                  <blockquote className="mt-6 text-white">
                    <p className="text-xl font-medium sm:text-2xl">
                      The Academic Planning Unit is a web app allowing the
                      administrative staff of crawford university manage the
                      data of the students, lecturers, generate reports and add
                      tasks. (sample login - UserID: 160502019, password:
                      111111)
                    </p>
                    <footer className="mt-6">
                      <a
                        href="http://cru-academic-planning-unit.herokuapp.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                      >
                        <LinkIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        View
                      </a>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </Bounce>
        <div className="relative bg-slate-900  py-6 lg:py-8">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-indigo-400 text-center lg:max-w-xl lg:text-left">
              More Projects
            </h2>
            <Roll right cascade>
              <div className="animate__animated animate__backInRight">
                <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                  {projects.map((project) => (
                    <div
                      key={project.title}
                      className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                    >
                      <div className="flex-1 bg-gray-800 p-6 flex flex-col justify-between">
                        <div className="flex-1">
                          <p className="text-sm font-medium text-indigo-600">
                            <a
                              href={project.href}
                              className="hover:underline flex"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <svg
                                className="h-8 w-8 text-gray-400 hover:text-indigo-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                />
                              </svg>
                            </a>
                          </p>
                          <a href={project.href} className="block mt-2">
                            <p className="text-xl font-semibold text-white">
                              {project.title}
                            </p>
                            <p className="mt-3 text-base text-white">
                              {project.description}
                            </p>
                          </a>
                        </div>
                        <div className="mt-6 flex items-center">
                          <div className="flex space-x-1 text-sm text-gray-500">
                            <span>{project.stack}</span>
                            <span aria-hidden="true">&middot;</span>
                            <span>{project.stackExtra}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Roll>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
