import { Link } from "react-scroll";
import React from "react";

const navigation = [
  { name: "About", href: "about", current: false },
  { name: "Experience", href: "experience", current: false },
  { name: "Projects", href: "projects", current: true },
  { name: "Socials", href: "socials", current: false },
];

export default function PortfolioFooter() {
  return (
    <footer className="bg-slate-900">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link
                to={item.href}
                smooth={true}
                duration={500}
                className="text-base text-gray-300 hover:text-indigo-400"
              >
                {item.name}
              </Link>
            </div>
          ))}
        </nav>

        <p className="mt-8 text-center text-base text-gray-300">
          Built by Abraham Kolawole
        </p>
      </div>
    </footer>
  );
}
