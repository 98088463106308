import React from "react";
import { stacks } from "../../utils/data";
import StackCard from "../../components/Cards/stack";

const Stack = () => {
  return (
    <main className="bg-slate-900" id="experience">
      <>
        <div className="pt-0 max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 sm:px-0">
            <h2 className="text-sm pb-12 font-semibold tracking-wide line-after">
              <span className="font-extrabold text-3xl text-white">Skills</span>
            </h2>

            <div className="">
              <div className="flex items-center justify-center flex-wrap gap-8">
                {stacks.map((stack) => (
                  <StackCard
                    src={stack.src}
                    alt={stack.alt}
                    value={stack.value}
                    key={stack.id}
                    id={stack.id}
                    tooltipId={stack.tooltipId}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    </main>
  );
};

export default Stack;
