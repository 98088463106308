import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import Spinner from "../../../src/assets/aka.png";
import { getPost } from "../../redux/actions/posts";
import { useParams } from "react-router-dom";

import PostItem from "./PostItem";
import { PostProps } from "../../utils/types";

type PostDetailsProps = {
  getPost: Function;
  posts: {
    post: PostProps;
    loading: boolean;
  };
};
const PostDetails = ({
  getPost,
  posts: { post, loading },
}: PostDetailsProps) => {
  const { id } = useParams();
  useEffect(() => {
    getPost(id);
  }, [getPost, id]);

  return loading || post === null ? (
    <img src={Spinner} alt="loading blog" />
  ) : (
    <Fragment>
      <PostItem post={post} />
    </Fragment>
  );
};

const mapStateToProps = (state: PostDetailsProps) => ({
  posts: state.posts,
});

export default connect(mapStateToProps, { getPost })(PostDetails);
