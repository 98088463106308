import React, { useEffect, useState } from "react";
import PortfolioFooter from "../../layouts/Footer";

import { useLocation } from "react-router-dom";
import Stack from "../../containers/Stack";
import Projects from "../../containers/Projects";
import Hero from "../../containers/Hero";
import Socials from "../../containers/Socials";
import Experience from "../../containers/Experience";
import Navbar from "../../layouts/Navbar";

const PortfolioMain = () => {
  const location = useLocation();
  const [openSocialAside, setOpenSocialAside] = useState<boolean>(false);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="min-h-screen App">
      <div className="relative overflow-hidden">
        <Navbar />
        <Hero
          setOpenSocialAside={setOpenSocialAside}
          openSocialAside={openSocialAside}
        />
        <Stack />
        <Experience />
        <Projects />
        <Socials
          openSocialAside={openSocialAside}
          setOpenSocialAside={setOpenSocialAside}
        />
        <PortfolioFooter />
      </div>
    </div>
  );
};

export default PortfolioMain;
