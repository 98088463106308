import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { ConnectedProps, connect } from "react-redux";
import { login } from "../../redux/actions/auth";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&`*+/=?_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

type LoginProps = {
  auth: {
    isAuthenticated: boolean;
  };
  isAuthenticated: boolean;
};

// Define the component props using `ConnectedProps`
type PropsFromRedux = ConnectedProps<typeof connector>;

function Login({ login, isAuthenticated }: PropsFromRedux) {
  const [formData, setFormData] = useState({
    password: "",
    email: "",
    formErrors: {
      password: "",
      email: "",
    },
  });

  const { email, password } = formData;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const { name, value } = e.target;
    let formErrors = formData.formErrors;

    switch (name) {
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "Invalid email address";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "Minimum of 6 characters required" : "";
        break;
      default:
        break;
    }
  };

  const onSubmit = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    login(email, password);
  };

  //Navigate to home
  if (isAuthenticated) {
    return <Navigate to="/admin/blog" />;
  }
  return (
    <div className="App min-h-screen bg-slate-900 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={require("../../assets/aka.png")}
          alt="Akad"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-100">
          Welcome to the Admin Dashboard
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          <a
            href="/"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            if you don't belong here, exit please
          </a>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={(e) => onSubmit(e)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => onChange(e)}
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={(e) => onSubmit(e)}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: LoginProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
  login,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Login);
