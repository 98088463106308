import { GET_POSTS, POST_ERROR, ADD_POST, GET_POST } from "./types";
import axios from "axios";
import { Dispatch } from "redux";
import { AppThunk, PostProps } from "../../utils/types";

//Get all posts in the db
export const getPosts = () => async (dispatch: Dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_VERCEL_URL}/posts`);

    dispatch({
      type: GET_POSTS,
      payload: res.data,
    });
  } catch (err: any) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//create post
export const createPost = (postData: PostProps) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_VERCEL_URL}/posts/add`, postData);
    dispatch({
      type: ADD_POST,
      payload: res.data,
    });
  } catch (err: any) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Get post detail
export const getPost = (id: string): AppThunk => async (dispatch: Dispatch) => {  
  try {
    const res = await axios.get(`${process.env.REACT_APP_VERCEL_URL}/posts/${id}`);
    dispatch({
      type: GET_POST,
      payload: res.data,
    });
  } catch (err: any) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
