import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import MeSmiling from "../../assets/post_pic.png";
import moment from "moment";
import PortfolioFooter from "../../layouts/Footer";
import Navbar from "./Navbar";
import { PostProps } from "../../utils/types";

export default function AllPosts() {
  const [Posts, setPosts] = useState<PostProps[]>([]);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = () => {
    Axios.get(`${process.env.REACT_APP_VERCEL_URL}/posts`).then((response) => {
      if (response.data) {
        setPosts(response.data);
      } else {
        alert("Failed to fetch data");
      }
    });
  };

  if (Posts.length === 0) {
    getPosts();
  }

  return (
    <>
      <Navbar />
      <div className="App relative bg-slate-900 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8 App">
        <div className="absolute inset-0">
          <div className="bg-slate-900 h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="">
            <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-indigo-400 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
              Blog
            </h1>
            <p className="mt-3 max-w-2xl text-xl text-gray-100 sm:mt-4">
              Browse through my recent blogs
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {Posts.map((post) => (
              <Link
                to={`/blog/${post._id}`}
                key={post._id}
                className="hover:shadow-lg hover:border-indigo-400 bg-gray-800 flex flex-col border border-gray-500 rounded-3xl shadow-lg overflow-hidden"
              >
                <div className="p-6 rounded-3xl flex-shrink-0">
                  <img
                    className=" rounded-3xl h-48 w-full object-cover"
                    src={post.images[0].url}
                    alt="Blog cover"
                  />
                </div>
                <div className="flex-1 bg-gray-800 px-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <ul>
                      {post.category.slice(0, 4).map((category, index) => (
                        <li
                          key={index}
                          className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                        >
                          {category}
                        </li>
                      ))}
                    </ul>

                    <p className="block mt-2 text-xl font-semibold text-white">
                      {post.title}
                    </p>
                  </div>
                  <div className=" py-4 flex items-center">
                    <div className="flex-shrink-0">
                      <span className="sr-only">Abraham Kolawole</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={MeSmiling}
                        alt="Abraham Kolawole"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-white">
                        Abraham Kolawole
                      </p>
                      <div className="flex space-x-1 text-sm text-gray-400">
                        <span className="">
                          {moment(post.createdAt).fromNow()}
                        </span>{" "}
                        <span aria-hidden="true">&middot;</span>
                        <span>{post.timeToRead} mins read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <PortfolioFooter />
    </>
  );
}
