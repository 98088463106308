import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

type PrivateRouteProps = {
  auth: {
    isAuthenticated: boolean;
    loading: boolean;
  };
  children: ReactNode;
};

function PrivateRoute({
  auth: { isAuthenticated, loading },
  children,
}: PrivateRouteProps) {
  return !isAuthenticated && !loading ? <Navigate to="/" /> : children;
}


const mapStateToProps = (state: PrivateRouteProps) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(
  PrivateRoute
);
