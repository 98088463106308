import React, { Dispatch, SetStateAction } from "react";

type HeroProps = {
  setOpenSocialAside: Dispatch<SetStateAction<boolean>>;
  openSocialAside: boolean;
};
export default function Hero({
  setOpenSocialAside,
  openSocialAside,
}: HeroProps) {
  return (
    <>
      <main className="pb-20 bg-slate-900">
        <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-24">
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">Abraham Kolawole</span>
                  </h1>
                  <h3 className="mt-1 text-2xl tracking-tight font-bold text-indigo-400 sm:text-2xl">
                    Fullstack Software Engineer
                  </h3>
                  <p className="w-80 mt-3 text-base text-gray-400 sm:text-xl lg:text-lg xl:text-xl">
                    I build the digital experience you want, and how you want
                    it!
                  </p>
                  <div className="mt-10 lg:mt-5">
                    <div className="sm:flex">
                      <div className="min-w-0 flex-1">
                        <button
                          onClick={() => setOpenSocialAside(!openSocialAside)}
                          className="text-white border-white border-2 px-4 py-4 text-bold"
                        >
                          Let&apos;s connect
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-20 -mb-16 sm:-mb-48 lg:ml-36 lg:relative">
                <div className="mx-auto ">
                  <img 
                    className="mx-auto rounded-full w-72 h-72 lg:absolute lg:inset-y-0 lg:left-0 lg:h-96 lg:w-96 lg:max-w-none"
                    src={require("../../assets/post_pic.png")}
                    alt="Abraham Kolawole"
                  />
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
