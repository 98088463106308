import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { createPost, getPost } from "../../redux/actions/posts";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from "react-router-dom";
import FileUpload from "./FileUpload";
import { useNavigate } from "react-router-dom";
import { AppDispatch, PostProps, RootState } from "../../utils/types";

type AddPostProps = {
  post: PostProps | null;
};

type CreatePostProps = {
  id?: string;
  title: string;
  category: string | string[] | any;
  timeToRead: string;
  images: any;
  description: string;
};

function AddPost() {
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getPost(id));
    }
  }, [id, dispatch]);

  const { post }: AddPostProps = useSelector((state: RootState) => state.posts);
  const description = post ? post.description : "";
  const titleProp = post ? post.title : "";
  const imagesProp = post ? post.images : [];
  const categoryProp = post ? post.category : "";
  const timeToReadProp = post ? post.timeToRead : "";
  const defaultImage = imagesProp.length === 0 ? [] : imagesProp;
  const [Image, setImage] = useState(defaultImage);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: titleProp,
    category: categoryProp,
    timeToRead: timeToReadProp,
  });

  let { title, category, timeToRead } = formData;

  const editorContent = post
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(description)))
    : EditorState.createEmpty();

  const [editorState, setEditorState] = useState({
    editorState: editorContent,
  });

  const updateImages = (
    newImages: React.SetStateAction<never[] | [{ url: string }]>
  ) => {
    setImage(newImages);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (editorState: EditorState) => {
    setEditorState({ editorState });
  };

  const onSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const images = Image;

    if (!title || !category || !timeToRead) {
      return alert("Fill all fields boss");
    }

    const newPost: CreatePostProps = {
      id,
      title,
      category,
      timeToRead,
      images,
      description: JSON.stringify(
        convertToRaw(editorState.editorState.getCurrentContent())
      ),
    };
    await dispatch(createPost(newPost));
    navigate("/admin/blog");
  };

  return (
    <div className="App bg-slate-900 text-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full transform translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <svg
          className="absolute right-full bottom-0 transform -translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            Add Blog
          </h2>
        </div>
        <div className="mt-12">
          <form className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div className="sm:col-span-2">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-white"
              >
                Title
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={title}
                  onChange={(e) => onChange(e)}
                  autoComplete="title"
                  className="text-black py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <FileUpload
                refreshFunction={updateImages}
                uploadHeaderText={"Cover Photo"}
                uploadText={"Upload a blog cover photo"}
              />
            </div>

            <div className="sm:col-span-2">
              {imagesProp && imagesProp.length !== 0
                ? imagesProp.map((image, index) => (
                    <div key={index}>
                      <img
                        style={{
                          minWidth: "300px",
                          width: "300px",
                          height: "240px",
                          paddingTop: "8px",
                        }}
                        src={image.url}
                        alt={`post-${index}`}
                      />
                    </div>
                  ))
                : null}
            </div>

            <div
              className="sm:col-span-2 w-full bg-white rounded-lg shadow-sm divide-y divide-gray-200"
              style={{ minHeight: "300px" }}
            >
              <label
                htmlFor="description"
                className="block text-sm font-medium text-white"
              >
                Description
              </label>
              <Editor
                editorState={editorState.editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                placeholder="Add something nice"
              />
            </div>

            <div>
              <label
                htmlFor="category"
                className="block text-sm font-medium text-white"
              >
                Category
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="category"
                  value={category}
                  onChange={(e) => onChange(e)}
                  id="category"
                  autoComplete="category"
                  className="text-black py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="timeToRead"
                className="block text-sm font-medium text-white"
              >
                Time To Read
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="timeToRead"
                  value={timeToRead}
                  onChange={(e) => onChange(e)}
                  id="timeToRead"
                  autoComplete="time"
                  className="text-black py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <button
                type="submit"
                onClick={onSubmit}
                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddPost;
