import React, { useState } from "react";
import Axios from "axios";
import Dropzone from "react-dropzone";
import SpinnerImg from "../../assets/Spinner.gif";

type FileUploadProps = {
  refreshFunction: Function;
  uploadHeaderText: string;
  uploadText: string;
};

const FileUpload = ({
  refreshFunction,
  uploadHeaderText,
  uploadText,
}: FileUploadProps) => {
  const [Images, setImages] = useState<string>();
  const [spinner, setSpinner] = useState(false);
  const onDrop = (files: (string | Blob)[]) => {
    let formData = new FormData();
    setSpinner(true);
    formData.append("file", files[0]);
    //save the Image we chose inside the Node Server
    Axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/image/cloudinary/upload/single`,
      formData,
      { headers: { "content-type": "multipart/form-data" } }
    ).then((response) => {
      if (response.data.success) {
        setSpinner(false);
        setImages(response.data.image.url);
        refreshFunction([response.data.image]);
      } else {
        alert("Failed to save the Image in Server");
      }
    });
  };

  return (
    <div className="App">
      <label className="block text-sm font-medium text-white">
        {uploadHeaderText}
      </label>
      {spinner ? <img src={SpinnerImg} alt="file uploading" /> : null}
      <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-100"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <div className="flex text-sm text-gray-100">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md font-medium text-indigo-400 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <span>{uploadText}</span>
              <Dropzone onDrop={onDrop} multiple={false} maxSize={800000000}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input
                      {...getInputProps()}
                      id="file-upload"
                      className="sr-only"
                    />
                  </div>
                )}
              </Dropzone>
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-100">PNG, JPG, GIF up to 10MB</p>
        </div>
      </div>

      <div>
        {Images && (
          <div>
            <img
              style={{
                minWidth: "300px",
                width: "300px",
                height: "240px",
                paddingTop: "10px",
              }}
              src={Images}
              alt="Selected to upload"
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default FileUpload;
