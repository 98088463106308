import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./Pages/PageNotFound";
import Portfolio from "./Pages/Landing";
import Login from "./Pages/admin/Login";
import AddPost from "./Pages/admin/AddPost";
import AllPosts from "./Pages/blog/AllPosts";
import { loadUser } from "./redux/actions/auth";
import store from "./redux/store/store";
import PostDetails from "./Pages/blog/Post";
import AdminBlog from "./Pages/admin/AllPosts";
import PrivateRoute from "./utils/PrivateRoute";
import ReactGA from "react-ga";
import "animate.css";

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  useEffect(() => {
    ReactGA.initialize("G-G13TLK5Z8C");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Portfolio />} />
        <Route path="/add-blog" element={<Portfolio />} />
        <Route path="/admin" element={<Login />} />
        <Route
          path="/admin/add-post"
          element={
            <PrivateRoute>
              <AddPost />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/post/:id"
          element={
            <PrivateRoute>
              <AddPost />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/blog"
          element={
            <PrivateRoute>
              <AdminBlog />
            </PrivateRoute>
          }
        />
        <Route path="/blog" element={<AllPosts />} />
        <Route path="/blog/:id" element={<PostDetails />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
