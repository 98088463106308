import React, { useState } from "react";
import MeSmiling from "../../assets/post_pic.png";
import moment from "moment";
import PortfolioFooter from "../../layouts/Footer";
import { ContentBlock, Editor, EditorState, convertFromRaw } from "draft-js";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import {
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { PostProps } from "../../utils/types";

type PostItemProps = {
  post: PostProps;
};
function PostItem({ post }: PostItemProps) {
  const contentState = convertFromRaw(JSON.parse(post.description));
  const editorState = EditorState.createWithContent(contentState);

  function myBlockStyleFn(contentBlock: ContentBlock) {
    const type = contentBlock.getType();
    if (type === "blockquote") {
      return "superFancyBlockquote";
    } else if (type === "code") {
      return "codeCheck";
    }
    return type;
  }
  const [copy, setCopy] = useState(false);

  const copyAlert = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  const copyLinkText = `http://www.abrahamkolawole.com/${post._id}`;
  return (
    <>
      <div className="App bg-slate-900 py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-2xl mx-auto">
          <svg
            className="absolute left-full transform translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>

          <nav className="flex pb-12" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <Link
                  to="/blog"
                  className="text-white ml-4  text-sm font-medium hover:no-underline"
                >
                  <div className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Back
                  </div>
                </Link>
              </li>
            </ol>
          </nav>

          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-100 sm:text-4xl">
              {post.title}
            </h2>
          </div>

          <ul className="pt-6">
            {post.category.slice(0, 4).map((category, index) => (
              <li
                key={index}
                className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
              >
                {category}
              </li>
            ))}
          </ul>

          <div className="mt-12">
            <div className=" py-4 flex items-center">
              <div className="flex-shrink-0">
                <span className="sr-only">Abraham Kolawole</span>
                <img
                  className="h-14 w-14 rounded-xl"
                  src={MeSmiling}
                  alt="Abraham Kolawole"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-white">
                  Abraham Kolawole
                </p>
                <div className="flex space-x-1 text-sm text-gray-400">
                  <span className="">{moment(post.createdAt).fromNow()}</span>{" "}
                  <span aria-hidden="true">&middot;</span>
                  <span>{post.timeToRead} mins read</span>
                </div>
              </div>
            </div>
          </div>

          <figure>
            <img
              className="w-full rounded-3xl"
              src={post.images[0].url}
              alt="Blog cover"
              width={1310}
              height={873}
            />
          </figure>

          <div className="text-white">
            <Editor
              onChange={() => {}}
              readOnly
              editorState={editorState}
              blockStyleFn={myBlockStyleFn}
            />
          </div>
          <div className="py-8 bg-slate-900">
            <h2 className="text-sm font-semibold">
              <span className="font-extrabold text-3xl text-indigo-400">
                Thank you for reading
              </span>
            </h2>
          </div>
          <div className="flex">
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-xl text-semibold text-indigo-400">Share</p>
              <div className="text-sm md:mt-0 md:ml-6">
                <span className="inline-flex">
                  <CopyToClipboard
                    text={copyLinkText}
                    onCopy={() => copyAlert()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer block bg-gray-100 rounded-full p-2 h-8 w-8 text-indigo-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                      />
                    </svg>
                  </CopyToClipboard>
                </span>

                <TwitterShareButton
                  url={`http://www.abrahamkolawole.com/${post._id}`}
                  title={post.title}
                  className="mr-2 ml-2"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <TwitterIcon
                    size={30}
                    round={true}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                </TwitterShareButton>

                <TelegramShareButton
                  url={`http://www.abrahamkolawole.com/${post._id}`}
                  title={post.title}
                  className="mr-2"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <TelegramIcon
                    size={30}
                    round={true}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                </TelegramShareButton>

                <WhatsappShareButton
                  url={`http://www.abrahamkolawole.com/${post._id}`}
                  className=""
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <WhatsappIcon
                    size={30}
                    round={true}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                </WhatsappShareButton>
              </div>
            </div>
          </div>

          {copy && (
            <div className="w-64 rounded-md bg-green-300 p-4">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm font-medium text-green-900">Copied</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <PortfolioFooter />
    </>
  );
}

export default PostItem;
